<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form">
      <a-card hoverable title="店铺认证">
        <a-form-model-item label="营业执照" prop="mobile" >
          <oss-multi-upload v-model="form.businessLicenseImage" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
        </a-form-model-item>
        <a-form-model-item label="法人名称" prop="legalPerson" >
          <a-input v-model="form.legalPerson" placeholder="请输入法人名称" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="mobile" >
          <a-input v-model="form.mobile" placeholder="请输入联系电话" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="店铺名称" prop="shopName" >
          <a-input v-model="form.shopName" placeholder="请输入店铺名称" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="统一社会信用代码" prop="businessLicenseUSCI" >
          <a-input v-model="form.businessLicenseUSCI" placeholder="请输入统一社会信用代码" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="图片" prop="img">
          <oss-multi-upload v-model="form.img" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
        </a-form-model-item>
        <a-form-model-item label="视频" prop="video">
          <oss-multi-upload v-model="form.video" type="video" :maxCount="0" :maxSize="200" :isRemove="false"></oss-multi-upload>
        </a-form-model-item>
      </a-card>
      <a-card hoverable title="补充信息" style="margin-top: 15px">
        <a-form-model-item label="店铺logo" prop="logo">
          <oss-multi-upload v-model="form.logo" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
        </a-form-model-item>
        <a-form-model-item label="所在地区 标准行政区划编码(到县)" prop="areaCode" >
          <a-input v-model="form.areaCode" placeholder="请输入所在地区 标准行政区划编码(到县)" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="省市区" >
          <a-input :value="areaContact()" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="address" >
          <a-input v-model="form.address" placeholder="请输入详细地址" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="经度" prop="longitude" >
          <a-input v-model="form.longitude" placeholder="请输入经度" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="纬度" prop="latitude" >
          <a-input v-model="form.latitude" placeholder="请输入纬度" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="客服电话,多个之间用逗号分隔" prop="servicePhone" >
          <a-input v-model="form.servicePhone" placeholder="请输入客服电话,多个之间用逗号分隔" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="客服微信号" prop="serviceWeChatNumber" >
          <a-input v-model="form.serviceWeChatNumber" placeholder="请输入客服微信号" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="店家说" prop="talkInfo" >
          <a-input v-model="form.talkInfo" placeholder="请输入店家说" :disabled="true" />
        </a-form-model-item>
      </a-card>
      <a-modal v-model="auditVisible" :loading="auditLoading" title="店铺审批" @ok="handleOk(4)">
        <a-textarea v-model="reason" placeholder="驳回原因" :maxlength="200" allow-clear/>
      </a-modal>
<!--      <a-form-model-item label="标签库id,多个之间用逗号分隔" prop="label" >-->
<!--        <a-input v-model="form.label" placeholder="请输入标签库id,多个之间用逗号分隔" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="图片 url半路径" prop="img" >-->
<!--        <a-input v-model="form.img" placeholder="请输入图片 url半路径" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="视频 url半路径" prop="video" >-->
<!--        <a-input v-model="form.video" placeholder="请输入视频 url半路径" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="盒装价格 元/人" prop="priceBoxed" >
      </a-form-model-item>
      <a-form-model-item label="城限价格 元/人" prop="priceCityLimit" >
      </a-form-model-item>
      <a-form-model-item label="独家价格 元/人" prop="priceSole" >
      </a-form-model-item>
      <a-form-model-item label="实景价格 元/人" prop="priceRealScene" >
      </a-form-model-item>
      <a-form-model-item label="微剧本价格 元/人" prop="priceMicro" >
      </a-form-model-item>
      <a-form-model-item label="驳回原因" prop="reason" >
        <a-input v-model="form.reason" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
        <a-input v-model="form.version" placeholder="请输入乐观锁" />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
<!--          <a-button type="primary" :loading="auditLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
          <a-button type="primary" :loading="auditLoading" @click="handleOk(3)">
            审核通过
          </a-button>
          <a-button type="primary" @click="auditVisible = true">
            审核驳回
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getShopAudit, addShopAudit, updateShopAudit, shopAuditVerify } from '@/api/shop/shopAudit'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    OssMultiUpload
  },
  data () {
    return {
      auditVisible: false,
      submitLoading: false,
      auditLoading: false,
      formTitle: '',
      reason: null,
      // 表单参数
      form: {
        id: null,
        legalPerson: null,
        mobile: null,
        businessLicenseImage: null,
        // inDoorImg: null,
        // outDoorImg: null,
        logo: null,
        shopName: null,
        businessLicenseUSCI: null,
        areaCode: null,
        longitude: null,
        latitude: null,
        address: null,
        servicePhone: null,
        serviceWeChatNumber: null,
        talkInfo: null,
        label: null,
        img: null,
        video: null,
        priceBoxed: null,
        priceCityLimit: null,
        priceSole: null,
        priceRealScene: null,
        priceMicro: null,
        reason: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        legalPerson: [
          { required: true, message: '法人名称不能为空', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
        businessLicenseImage: [
          { required: true, message: '店铺营业执照不能为空', trigger: 'blur' }
        ],
        // inDoorImg: [
        //   { required: true, message: '店铺内景不能为空', trigger: 'blur' }
        // ],
        // outDoorImg: [
        //   { required: true, message: '店铺外景不能为空', trigger: 'blur' }
        // ],
        logo: [
          { required: true, message: '店铺logo不能为空', trigger: 'blur' }
        ],
        areaCode: [
          { required: true, message: '所在地区 标准行政区划编码(到县)不能为空', trigger: 'blur' }
        ],
        longitude: [
          { required: true, message: '经度不能为空', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: '纬度不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '详细地址不能为空', trigger: 'blur' }
        ],
        servicePhone: [
          { required: true, message: '客服电话,多个之间用逗号分隔不能为空', trigger: 'blur' }
        ],
        talkInfo: [
          { required: true, message: '店家说不能为空', trigger: 'blur' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        legalPerson: null,
        mobile: null,
        businessLicenseImage: null,
        inDoorImg: null,
        outDoorImg: null,
        logo: null,
        shopName: null,
        businessLicenseUSCI: null,
        areaCode: null,
        longitude: null,
        latitude: null,
        address: null,
        servicePhone: null,
        serviceWeChatNumber: null,
        talkInfo: null,
        label: null,
        img: null,
        video: null,
        priceBoxed: null,
        priceCityLimit: null,
        priceSole: null,
        priceRealScene: null,
        priceMicro: null,
        reason: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    areaContact () {
      let area = ''
      if (this.form.areap) {
        area += this.form.areap
      }
      if (this.form.areac) {
        area += this.form.areac
      }
      if (this.form.areax) {
        area += this.form.areax
      }
      return area
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShopAudit(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShopAudit(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addShopAudit(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    handleOk (shopStatus) {
      shopAuditVerify({
        id: this.form.id,
        status: shopStatus,
        reason: this.reason
      }).then(response => {
        if (response.success) {
          this.$notification.success({
            message: '审批成功'
          })
        }
      }).finally(() => {
        this.open = false
        this.shopStatus = '3'
        this.$emit('ok')
        this.auditVisible = false
        this.visible = false
        this.selectedId = ''
        this.reason = ''
      })
    }
  }
}
</script>
<style lang="css" scoped>
>>> .ant-form .ant-card-hoverable {
  cursor: initial;
}
.bottom-control {
  z-index: 999;
}
</style>
